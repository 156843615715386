.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.owl-carousel .owl-stage {
	-moz-backface-visibility: hidden;
	-ms-touch-action: pan-Y;
	-webkit-backface-visibility: hidden;
	touch-action: pan-y;
}

th {
	text-align: -webkit-match-parent;
}

.loader {
	display: inline-block;
	width: 40px;
	height: 40px;
}

.loader:after {
	content: " ";
	display: block;
	width: 30px;
	height: 30px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: loader 1.2s linear infinite;
}
@keyframes loader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spinner {
	display: -webkit-inline-box;
	width: 100px;
	height: 100px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	justify-content: center;
	align-items: center;
}

.tab-containts {
	width: 100%;
	min-height: 500px;
	position: absolute;
}
