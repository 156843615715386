/*
[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Wizard
- 2.2 Success submit
- 2.3 Inner pages

3. COMMON
- 3.1 Misc
- 3.2 Spacing

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/
/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/
html,
body {
	height: 100%;
}

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background: #fff;
	font-family: "Open Sans", sans-serif !important;
	font-display: fallback;
	color: #fff;
}

#rcc-decline-button {
	background: none !important;
	color: rgba(255, 255, 255, 0.65) !important;
	margin: 15px;
	padding: 10px 25px !important;
	font-size: 16px !important;
	letter-spacing: 0 !important;
	font-weight: 300 !important;
	border: none !important;
}

.grecaptcha-badge {
	visibility: hidden;
}

#constent {
	justify-content: center !important;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
	visibility: hidden !important;
	display: none !important;
	pointer-events: none !important;
	height: 0 !important;
	width: 0 !important;
	margin: 0 !important;
}

#rcc-confirm-button {
	color: #fff !important;
	background: linear-gradient(114deg, #ff742a, #ff345f) 0 0 no-repeat !important;
	margin: 15px;
	padding: 10px 25px !important;
	font-size: 16px !important;
	letter-spacing: 0 !important;
	font-weight: 300 !important;
	border: none !important;
}

#mavibold {
	font-size: 12pt;
	color: #0d6efd !important;
}

button[disabled] {
	display: flex !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000;
}

p {
	margin-bottom: 25px;
}

strong {
	font-weight: 500;
}

label {
	font-weight: 400;
	margin-bottom: 3px;
	color: #222;
}

hr {
	margin: 30px 0 30px 0;
	border-color: #ddd;
}

ul,
ol {
	list-style: none;
	margin: 0 0 25px 0;
	padding: 0;
}

/*General links color*/
a {
	color: #ffffff;
	text-decoration: none !important;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	outline: none;
}

a:hover,
a:focus {
	color: #111;
	text-decoration: none;
	outline: none;
}

#terms {
	color: #0d6efd;
}

a.animated_link {
	position: relative;
	text-decoration: none;
}

a.animated_link {
	position: relative;
	text-decoration: none;
}

a.animated_link:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: -5px;
	opacity: 1;
	left: 0;
	background-color: #0052db;
	visibility: hidden;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

a.animated_link:hover:before {
	visibility: visible;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

a.animated_link.active {
	position: relative;
	text-decoration: none;
	color: #0052db;
}

a.animated_link.active:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: -5px;
	opacity: 1;
	left: 0;
	background-color: #0052db;
	visibility: visible;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

/*-------- 1.2 Buttons --------*/
a.btn_1,
.btn_1 {
	border: none;
	color: #fff;
	background: #0052db;
	outline: none;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	padding: 12px 25px;
	color: #fff;
	font-weight: 600;
	text-align: center;
	line-height: 1;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	font-size: 14px;
	font-size: 0.875rem;
}

a.btn_1:hover,
.btn_1:hover {
	background-color: #ff345f;
}

a.btn_1.full-width,
.btn_1.full-width {
	display: block;
	width: 100%;
	text-align: center;
	margin-bottom: 5px;
}

a.btn_1.small,
.btn_1.small {
	padding: 7px 10px;
	font-size: 13px;
	font-size: 0.8125rem;
}

a.btn_1.medium,
.btn_1.medium {
	font-size: 16px;
	font-size: 1rem;
	padding: 18px 30px;
}

a.btn_1.rounded,
.btn_1.rounded {
	-webkit-border-radius: 25px !important;
	-moz-border-radius: 25px !important;
	-ms-border-radius: 25px !important;
	border-radius: 25px !important;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}

/*-------- 1.3 Structure --------*/
/* Preloader */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	background-color: #fff;
	z-index: 999999;
}

[data-loader="circle-side"] {
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	-webkit-animation: circle infinite 0.95s linear;
	-moz-animation: circle infinite 0.95s linear;
	-o-animation: circle infinite 0.95s linear;
	animation: circle infinite 0.95s linear;
	border: 2px solid #333;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-right-color: rgba(0, 0, 0, 0.2);
	border-bottom-color: rgba(0, 0, 0, 0.2);
	border-radius: 100%;
}

#loader_form {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 999999;
	display: none;
}

[data-loader="circle-side-2"] {
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	-webkit-animation: circle infinite 0.95s linear;
	-moz-animation: circle infinite 0.95s linear;
	-o-animation: circle infinite 0.95s linear;
	animation: circle infinite 0.95s linear;
	border: 2px solid #333;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-right-color: rgba(0, 0, 0, 0.2);
	border-bottom-color: rgba(0, 0, 0, 0.2);
	border-radius: 100%;
}

@-webkit-keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.row-height {
	height: auto;
}

@media screen and (min-width: 1920px) {
	.row-height {
		height: 100vh;
	}
}

@media screen and (min-width: 3840px) {
	.row-height {
		height: 100vh;
	}
}

@media (max-width: 991px) {
	.row-height {
		height: auto;
	}
}

.content-left {
	background-color: #ffffff;
	padding: 0;
}

.content-left-wrapper {
	display: block;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	min-height: 100%;
	padding: 60px 90px 35px 90px;
	background-color: #ffffff;

	text-align: center;
	position: relative;
}

@media (max-width: 991px) {
	.content-left-wrapper {
		height: auto;
		padding: 95px 30px 35px 30px;
	}
}

@media (max-width: 767px) {
	.content-left-wrapper {
		padding: 95px 15px 35px 15px;
	}
}

@media (max-width: 991px) {
	.content-left-wrapper figure img {
		height: 150px;
	}
}

.content-left-wrapper h2 {
	color: #fff;
	font-size: 32px;
	font-size: 2rem;
	margin: 20px 0 15px 0;
	font-weight: 400;
}

@media (max-width: 767px) {
	.content-left-wrapper h2 {
		font-size: 26px;
		font-size: 1.625rem;
	}
}

.content-left-wrapper p {
	font-size: 15px;
	font-size: 0.9375rem;
	opacity: 0.8;
}

@media (max-width: 767px) {
	.content-left-wrapper p {
		font-size: 14px;
		font-size: 0.875rem;
	}
}

.content-left-wrapper .btn_1 {
	margin: 25px 0 25px 0;
}

@media (max-width: 991px) {
	.content-left-wrapper .btn_1 {
		display: none;
	}
}

.content-left-wrapper .btn_1.mobile_btn {
	display: none;
}

@media (max-width: 767px) {
	.content-left-wrapper .btn_1.mobile_btn {
		margin: 5px 0 30px 0;
		display: inline-block;
	}
}

.content-right {
	background-color: #0052db;
	padding: 60px;
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
	background: transparent;
	background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.5));
	background: linear-gradient(to bottom, #00b3ff, rgb(29 0 107));
}

@media (max-width: 991px) {
	.content-right {
		height: auto;
		padding: 30px 15px;
	}
}

#boldie {
	color: #000 !important;
	font-size: 14pt;
}

button[disabled] {
	display: block !important;
}

.cta {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	color: white;
	text-align: center;
	background-color: #ff6b32c2;
	backdrop-filter: blur(6px);
}

.cta li {
	display: inline-block;
	font-size: 15px;
	list-style-type: none;
	padding: 1em;
	text-transform: uppercase;
}

.cta li span {
	display: block;
	font-size: 25px;
}

.content-right {
	padding: 30 !important;
	height: auto !important;
}

a#logo {
	position: absolute;
	top: 15px;
	display: block;
	height: 35px;
}

#social {
	position: absolute;
	top: 15px;
	right: 20px;
}

#social ul {
	margin: 0;
	padding: 0;
	text-align: center;
}

#social ul li {
	float: left;
	margin: 0 5px 10px 0;
	list-style: none;
}

#social ul li a {
	color: #0052db;
	opacity: 0.6;
	text-align: center;
	line-height: 35px;
	display: block;
	font-size: 16px;
	font-size: 1rem;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#social ul li a:hover {
	opacity: 1;
}

.copy {
	position: absolute;
	bottom: 25px;
	left: 0;
	width: 100%;
	opacity: 0.5;
}

@media (max-width: 991px) {
	.copy {
		display: none;
	}
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/
/*-------- 2.1 Wizard --------*/
#left_form {
	text-align: center;
}

#left_form h2 {
	font-size: 28px;
	font-size: 1.75rem;
	color: #0686d8;
}

@media (max-width: 767px) {
	#left_form figure img {
		height: 130px;
		width: auto;
	}
}

input#website {
	display: none;
}

#wizard_container {
	width: 100%;
}

@media (max-width: 767px) {
	#wizard_container {
		width: 100%;
	}
}

h3.main_question {
	margin: 0 0 20px 0;
	padding: 0;
	font-weight: 500;
	font-size: 18px;
	font-size: 1.125rem;
	color: #000;
}

h3.main_question strong {
	display: block;
	color: #999;
	margin-bottom: 5px;
}

/* Wizard Buttons*/
button.backward,
button.forward,
button.submit {
	border: none;
	color: #fff;
	text-decoration: none;
	transition: background 0.5s ease;
	-moz-transition: background 0.5s ease;
	-webkit-transition: background 0.5s ease;
	-o-transition: background 0.5s ease;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-align: center;
	background: #0052db;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 1;
	padding: 12px 30px;
}

button.backward {
	color: #777;
	background: #e8e8e8;
}

button[disabled] {
	display: none;
}

button.submit:before {
	content: "\4e";
	font-family: "ElegantIcons";
	position: absolute;
	top: 8px;
	right: 10px;
	font-size: 18px;
	font-size: 1.125rem;
}

.backward:hover,
.forward:hover {
	background: #ff345f;
	color: #fff;
}

#top-wizard {
	padding-bottom: 20px;
}

#asd h2,
p {
	margin-bottom: 10px;
	color: #000000 !important;
	font-weight: 500;
}

.benefit {
	margin-bottom: 25px;
}

.country-name {
	color: #000;
}

#middle-wizard {
	min-height: 330px;
}

@media (max-width: 991px) {
	#middle-wizard {
		min-height: inherit;
	}
}

#bottom-wizard {
	border-top: 2px solid #ededed;
	padding-top: 20px;
	text-align: right;
	margin-top: 25px;
}

.ui-widget-content {
	background-color: transparent;
}

.ui-widget-content a {
	color: #222222;
}

.ui-widget-header {
	background: #6c3;
}

.ui-widget-header a {
	color: #222222;
}

.ui-progressbar {
	height: 2px;
	width: 100%;
}

.ui-progressbar .ui-progressbar-value {
	height: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.summary ul {
	margin: 0;
	padding: 0;
}

.summary ul li {
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #ededed;
	position: relative;
	padding-left: 45px;
	margin-bottom: 25px;
}

.summary ul li:last-child {
	margin-bottom: 0;
	border-bottom: none;
}

.summary ul li strong {
	display: block;
	line-height: 26px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	position: absolute;
	left: 0;
	top: 0;
	text-align: center;
	border: 2px solid #ddd;
}

.summary ul li h5 {
	padding-top: 6px;
	font-size: 15px;
	font-size: 0.9375rem;
	font-weight: 500;
	color: #0686d8;
}

.summary ul li ul {
	margin: 20px 0 25px 0;
	padding: 0;
}

.summary ul li ul li {
	margin: 0;
	padding: 0;
	border-bottom: 0;
}

.summary label {
	font-weight: 500;
}

/*-------- 2.2 Success submit --------*/
#success {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 300px;
	height: 190px;
	margin-top: -85px;
	margin-left: -150px;
	text-align: center;
}

#success h4 {
	font-weight: 400;
	margin: 20px 0 0 0;
	font-size: 18px;
	font-size: 1.125rem;
}

#success h4 span {
	display: block;
	margin-bottom: 0;
	font-weight: 500;
	font-size: 21px;
	font-size: 1.3125rem;
}

@-webkit-keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@-ms-keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}

	100% {
		stroke-dashoffset: 480px;
	}
}

@-ms-keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}

	100% {
		stroke-dashoffset: 480px;
	}
}

@keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}

	100% {
		stroke-dashoffset: 480px;
	}
}

.inlinesvg .svg svg {
	display: inline;
}

.icon--order-success svg path {
	-webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
	animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
	-webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
	animation: checkmark-circle 0.6s ease-in-out backwards;
}

/*-------- 2.3 Inner pages --------*/
header {
	position: relative;
	padding: 15px 0;
	background-color: #fff;
	border-bottom: 1px solid #d9e1e6;
}

header .cd-nav-trigger {
	top: -5px !important;
}

header #social {
	right: 80px;
	top: 0;
}

header #social ul li a {
	color: #333;
}

#reverse {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.fogo {
	width: 150px;
	height: 120px;
}

.fogoHeader {
	height: 120px;
	vertical-align: middle;
	text-align: center;
}

.floatLeft {
	float: left;
}

.floatRight {
	float: right;
}

/* Footer */

.main_title {
	text-align: center;
}

.main_title h2 {
	margin: 0 0 10px 0;
	padding: 0;
	font-size: 42px;
	font-size: 2.625rem;
	color: #0052db;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.main_title h2 {
		font-size: 32px;
		font-size: 2rem;
	}
}

.main_title h2 em {
	display: block;
	width: 40px;
	height: 4px;
	background-color: #ededed;
	margin: auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 15px;
}

.main_title p {
	font-size: 18px;
	font-size: 1.125rem;
	padding: 0 10%;
	margin-bottom: 45px;
	color: #777;
}

@media (max-width: 767px) {
	.main_title p {
		font-size: 16px;
		font-size: 1rem;
	}
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #0052db !important;
}

main#general_page {
	background-color: #fff;
}

iframe#map_iframe {
	width: 100%;
	height: 450px;
	border: 0;
}

@media (max-width: 991px) {
	iframe#map_iframe {
		height: 400px;
	}
}

.box_style_2 {
	background-color: #f8f8f8;
	padding: 25px 30px 30px 30px;
	position: relative;
	margin-bottom: 25px;
}

.box_style_2 .form-control {
	background-color: #fff !important;
}

.box_style_2 hr {
	margin: 10px -30px 20px -30px;
	border: 0;
	border-top: 2px solid #fff;
}

ul.contacts_info {
	list-style: none;
	padding: 0;
	margin: 15px 0 0 0;
}

ul.contacts_info li {
	margin-bottom: 15px;
}

ul.contacts_info li:last-child {
	margin-bottom: 0;
}

.parallax_window_in {
	height: 420px;
	position: relative;
	display: table;
	width: 100%;
}

#sub_content_in {
	display: table-cell;
	padding: 45px 15% 0 15%;
	vertical-align: middle;
	text-align: center;
	background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
	#sub_content_in {
		padding: 45px 30px 0 30px;
	}
}

#sub_content_in h1 {
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 46px;
	font-size: 46px;
	font-size: 2.875rem;
	margin-bottom: 0;
}

@media (max-width: 767px) {
	#sub_content_in h1 {
		font-size: 36px;
		font-size: 2.25rem;
	}
}

#sub_content_in p {
	color: #fff;
	font-size: 24px;
	font-size: 1.5rem;
	font-weight: 300;
}

@media (max-width: 767px) {
	#sub_content_in p {
		font-size: 21px;
		font-size: 1.3125rem;
	}
}

.container_styled_1 {
	background: #f9f9f9;
}

.team-item-img {
	position: relative;
}

.team-item-img .team-item-detail {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
	text-align: center;
	color: #fff;
	display: -webkit-flex;
	display: flex;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	transition: all 0.5s ease-in-out 0s;
	-moz-transition: all 0.5s ease-in-out 0s;
	-webkit-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
}

.team-item:hover .team-item-detail {
	opacity: 1;
	visibility: visible;
}

.team-item-img .team-item-detail .team-item-detail-inner {
	margin: auto;
	padding: 25px;
}

.team-item-detail-inner h4 {
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
}

.team-item-detail-inner .social {
	margin: 0 0px 25px 0px;
	padding: 0px;
}

.team-item-detail-inner .social li {
	list-style: none;
	display: inline-block;
	margin: 0px 5px;
}

.team-item-detail-inner .social li a {
	color: #fff;
}

.team-item-detail-inner .social li a:hover {
	color: #ff345f;
}

.team-item-info {
	padding-top: 15px;
	text-align: center;
	text-transform: uppercase;
}

.team-item-info h4 {
	margin-bottom: 0px;
}

.error_message {
	font-weight: 500;
	color: #ee5050;
}

/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/
/*-------- 3.1 Misc --------*/
.modal-content {
	border: none;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

.form-group {
	position: relative;
	margin-bottom: 1rem;
}

.form-group.terms {
	padding: 12px 0 0 0;
}

.form-group i {
	font-size: 18px;
	font-size: 1.125rem;
	position: absolute;
	right: 5px;
	top: 11px;
	color: #ccc;
	width: 25px;
	height: 25px;
	display: block;
	font-weight: 400 !important;
}

span.error {
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	font-size: 12px;
	position: absolute;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	top: -20px;
	right: -15px;
	z-index: 2;
	height: 25px;
	line-height: 1;
	background-color: #e34f4f;
	color: #fff;
	font-weight: normal;
	display: inline-block;
	padding: 6px 8px;
}

span.error:after {
	content: "";
	position: absolute;
	border-style: solid;
	border-width: 0 6px 6px 0;
	border-color: transparent #e34f4f;
	display: block;
	width: 0;
	z-index: 1;
	bottom: -6px;
	left: 20%;
}

.container_radio.version_2 .error,
.container_check.version_2 .error {
	left: -15px;
	top: -30px;
	right: inherit;
}

.radio_input .error {
	left: -15px;
	top: -30px;
	right: inherit;
}

.styled-select span.error {
	top: -20px;
}

.terms span.error {
	top: -30px;
	left: -15px;
	right: inherit;
}


.signout {
	border-color: #0d6efd;
	color: white;
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 999999;
	width: inherit;
	border: 1px solid #d2d8dd;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	font-size: 14px;
	font-size: 0.875rem;
	height: calc(2.55rem + 2px);
	box-shadow: unset !important;
}

.form-control {
	border: 1px solid #d2d8dd;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	font-size: 14px;
	font-size: 0.875rem;
	height: calc(2.55rem + 2px);
	border-right-color: #d2d8dd !important;
	border-top-color: #d2d8dd !important;
	border-bottom-color: #d2d8dd !important;
	box-shadow: unset !important;
	border-left-color: #d2d8dd !important;
}

.form-control:focus {
	box-shadow: none;
	border-color: #0052db;
}

#btn-send {
	background-color: #0d6efd;
	color: white;
}

.rating_wp {
	background-color: #f9f9f9;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	padding: 14px 10px 10px 15px;
	margin-bottom: 5px;
}

.kayit {
	color: #000;
}

.kayit h2 {
	color: #000;
}

.sms {
	margin-bottom: 10px;
}

.rating {
	display: inline-block;
	font-size: 0;
	float: right;
	position: relative;
}

@media (max-width: 767px) {
	.rating {
		float: left;
		display: block;
	}
}

.rating span.error {
	top: -30px;
}

.rating_type {
	float: left;
	font-size: 16px;
	font-size: 1rem;
	font-weight: 500 !important;
	font-weight: normal;
	color: #0052db;
}

@media (max-width: 767px) {
	.rating_type {
		float: none;
		display: block;
		margin-bottom: 5px;
	}
}

.rating-input {
	float: right;
	padding: 0;
	margin: 0 0 0 0;
	opacity: 0;
	width: 4px;
}

.modal-body {
	text-align: left;
	color: black;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
	color: black;
}

#isbank {
	height: 35px;
	margin-left: 20px;
	margin-right: 0px !important;
}

#maxi {
	margin-left: 0px !important;
	margin-right: 20px;
}

#mailLink {
	color: #ffffff;
}

.carousel-item h1,
h2,
h3,
h4,
h5,
h6 {
	color: #ffffff;
}

.review_message {
	height: 250px !important;
}

@media (max-width: 767px) {
	.review_message {
		height: 200px !important;
	}
}

/* Checkbox style */
.container_check {
	display: flex;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	padding-left: 30px;
	line-height: 1.3;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.container_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.container_check input:checked~.checkmark {
	background-color: #0052db;
	border: 1px solid transparent;
}

.container_check .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #d2d8dd;
	background-color: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.container_check .checkmark:after {
	content: "";
	position: absolute;
	display: none;
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.container_check.version_2 {
	padding: 6px 0 0 45px;
	min-height: 30px;
}

.container_check.version_2 .checkmark {
	height: 30px;
	width: 30px;
}

.container_check.version_2 .checkmark:after {
	left: 12px;
	top: 8px;
	width: 5px;
	height: 10px;
}

.container_check input:checked~.checkmark:after {
	display: block;
}

/* Radio buttons */
.container_radio {
	display: block;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	padding-left: 30px;
	line-height: 1.3;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.container_radio input {
	position: absolute;
	opacity: 0;
}

.container_radio input:checked~.checkmark:after {
	opacity: 1;
}

.container_radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
}

.container_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #0052db;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.container_radio.version_2 {
	padding: 6px 0 0 45px;
	min-height: 30px;
}

.container_radio.version_2 input:checked~.checkmark:before {
	opacity: 1;
}

.container_radio.version_2 .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 30px;
	border: 1px solid #ccc;
	border-radius: 50%;
}

.container_radio.version_2 .checkmark:after {
	width: 30px;
	height: 30px;
	top: -1px;
	left: -1px;
}

.container_radio.version_2 .checkmark:before {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	left: 12px;
	top: 8px;
	width: 5px;
	height: 10px;
	border: solid white;
	z-index: 999;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.value {
	color: #000 !important;
}

.zaman {
	display: flex;
	margin-left: 15px;
}

input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

@font-face {
	font-family: text-security-disc;
	src: url("https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff");
	font-display: swap;
}

#password1 {
	-webkit-text-security: disc;
}

#password2 {
	-webkit-text-security: disc;
}

/* Show/hide password */
.my-toggle {
	background: transparent;
	border: 0;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	color: #888;
	cursor: pointer;
	font-size: 10px;
	font-size: 10px;
	font-size: 0.625rem;
	font-weight: bold;
	margin-right: 5px;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	text-transform: uppercase;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: #fff;
}

.my-toggle:hover,
.my-toggle:focus {
	background-color: #eee;
	color: #555;
	outline: transparent;
}

.hideShowPassword-wrapper {
	width: 100% !important;
}

/*Password strength */
#pass-info {
	width: 100%;
	margin-bottom: 15px;
	color: #555;
	text-align: center;
	font-size: 12px;
	font-size: 0.75rem;
	padding: 5px 3px 3px 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
}

#pass-info.weakpass {
	border: 1px solid #ff9191;
	background: #ffc7c7;
	color: #94546e;
}

#pass-info.stillweakpass {
	border: 1px solid #fbb;
	background: #fdd;
	color: #945870;
}

#pass-info.goodpass {
	border: 1px solid #c4eec8;
	background: #e4ffe4;
	color: #51926e;
}

#pass-info.strongpass {
	border: 1px solid #6ed66e;
	background: #79f079;
	color: #348f34;
}

#pass-info.vrystrongpass {
	border: 1px solid #379137;
	background: #48b448;
	color: #cdffcd;
}

.radio_input .container_radio {
	display: inline-block;
	margin: 12px 0 0 12px;
}

/* Fileupload */
.fileupload {
	position: relative;
	width: 100%;
	margin-top: 5px;
}

input[type="file"] {
	border: 1px solid #d2d8dd;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	padding: 5px;
	height: auto;
	width: 100%;
	color: #999;
}

input[type="file"]:focus {
	box-shadow: none;
	outline: none;
}

/* Jquery select */
.nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #fff;
	border-radius: 3px;
	border: 1px solid #d2d8dd;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	height: 42px;
	line-height: 40px;
	outline: none;
	padding-left: 15px;
	padding-right: 27px;
	position: relative;
	text-align: left !important;
	transition: all 0.2s ease-in-out;
	user-select: none;
	white-space: nowrap;
	width: auto;
	color: #6c757d;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
	border-color: #0052db;
}

.nice-select:after {
	border-bottom: 2px solid #ccc;
	border-right: 2px solid #ccc;
	content: "";
	display: block;
	height: 8px;
	margin-top: -5px;
	pointer-events: none;
	position: absolute;
	right: 20px;
	top: 50%;
	transform-origin: 66% 66%;
	transform: rotate(45deg);
	transition: all 0.15s ease-in-out;
	width: 8px;
}

.nice-select.open:after {
	transform: rotate(-135deg);
}

.nice-select.open .list {
	opacity: 1;
	pointer-events: auto;
	transform: scale(1) translateY(0);
}

.nice-select.disabled {
	border-color: #9e9e9e;
	color: #999;
	pointer-events: none;
}

.nice-select.disabled:after {
	border-color: white;
}

.nice-select.wide {
	width: 100%;
}

.nice-select.wide .list {
	left: -1px !important;
	right: -1px !important;
}

.nice-select.right {
	float: right;
}

.nice-select.right .list {
	left: auto;
	right: 0;
}

.nice-select.small {
	font-size: 12px;
	height: 42px;
	line-height: 40px;
}

.nice-select.small:after {
	height: 4px;
	width: 4px;
}

.nice-select.small .option {
	line-height: 40px;
	min-height: 40px;
}

.nice-select .list {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
	box-sizing: border-box;
	margin-top: 4px;
	opacity: 0;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	position: absolute;
	top: 100%;
	left: 0;
	transform-origin: 50% 0;
	transform: scale(0.75) translateY(-25px);
	transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
	z-index: 9999;
	height: 23vh;
	overflow: auto;
}

.nice-select .list:hover .option:not(:hover) {
	background-color: transparent !important;
}

.nice-select .list::-webkit-scrollbar {
	width: 14px;
	height: 18px;
}

.nice-select .list::-webkit-scrollbar-thumb {
	height: 6px;
	border: 4px solid transparent;
	background-clip: padding-box;
	-webkit-border-radius: 7px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
		inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.nice-select .list::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}

.nice-select .list::-webkit-scrollbar-corner {
	background-color: transparent;
}

.nice-select .option {
	cursor: pointer;
	font-weight: 400;
	line-height: 38px;
	list-style: none;
	min-height: 38px;
	outline: none;
	padding-left: 15px;
	padding-right: 26px;
	text-align: left;
	transition: all 0.2s;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
	background-color: #f6f6f6;
}

.nice-select .option.selected {
	font-weight: 500;
}

.nice-select .option.disabled {
	background-color: transparent;
	color: #999;
	cursor: default;
}

.no-csspointerevents .nice-select .list {
	display: none;
}

.no-csspointerevents .nice-select.open .list {
	display: block;
}

/* Budget slider */
.budget_slider {
	background-color: #f8f8f8;
	margin-bottom: 20px;
	padding: 45px 30px 15px 30px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
}

.budget_slider span {
	display: block;
	font-weight: 600;
	color: #0052db;
	font-size: 24px;
	font-size: 1.5rem;
	margin-top: 25px;
}

.budget_slider span:before {
	content: "$";
}

.rangeslider__handle {
	border: 2px solid #0052db !important;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.rangeslider__fill {
	background: #0052db !important;
}

/*-------- 3.2 Spacing --------*/
.add_bottom_10 {
	margin-bottom: 10px;
}

.add_bottom_15 {
	margin-bottom: 15px;
}

.add_bottom_30 {
	margin-bottom: 30px;
}

.add_bottom_45 {
	margin-bottom: 45px;
}

.add_bottom_60 {
	margin-bottom: 60px;
}

.add_bottom_75 {
	margin-bottom: 75px;
}

.add_top_10 {
	margin-top: 10px;
}

.add_top_15 {
	margin-top: 15px;
}

.add_top_20 {
	margin-top: 20px;
}

.add_top_30 {
	margin-top: 30px;
}

.add_top_60 {
	margin-top: 60px;
}

.more_padding_left {
	padding-left: 40px;
}

.nomargin_top {
	margin-top: 0;
}

.nopadding {
	margin: 0 !important;
	padding: 0 !important;
}

.nomargin {
	margin: 0 !important;
}

.margin_30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.margin_60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.margin_60_35 {
	padding-top: 60px;
	padding-bottom: 35px;
}

.col-md-10 {
	text-align: left;
}

#logorow {
	text-align: left;
}

@media (max-width: 700px) {
	.col-md-10 {
		text-align: center;
	}

	#logorow {
		text-align: center;
	}
}

@media (max-width: 830px) {
	#logorow {
		text-align: center;
	}
}

.react-tel-input .form-control {
	width: 100% !important;
}

#step1-progress {
	width: 33%;
	aria-valuenow: 33;
	aria-valuemin: 0;
	aria-valuemax: 100;
	margin-bottom: 20px;
	height: 0.2rem !important;
	width: 100%;
}

#step2-progress {
	width: 66%;
	aria-valuenow: 66;
	aria-valuemin: 0;
	aria-valuemax: 100;
	margin-bottom: 20px;
	height: 0.2rem !important;
	width: 100%;
}

#step3-progress {
	width: 100%;
	aria-valuenow: 100;
	aria-valuemin: 0;
	aria-valuemax: 100;
	margin-bottom: 20px;
	height: 0.2rem !important;
	width: 100%;
}

.progress {
	height: 10px;
	margin-bottom: 20px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
	background-color: #919191 !important;
	border-color: #000000 !important;
}